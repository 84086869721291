import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import InvestingWithGrandviewContent from "../components/InvestingWithGrandviewContent";
import AboutContent from "../components/AboutContent";
import FlyoutLink from "../components/FlyoutLink";
import Flyout from "../components/Flyout";
import { useTranslation } from "react-i18next";
import { MdOutlineMenu } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const Header = ({
  langMenuWidth,
  menuItemAlignSelf,
  investingWithGrandviewFlex,
  menuItemAlignSelf1,
  menuItemLineHeight,
}) => {
  const { t, i18n } = useTranslation("header");
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  // 监听路由变化，自动关闭侧边栏
  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const mainNavStyle = useMemo(() => {
    return {
      width: langMenuWidth,
    };
  }, [langMenuWidth]);

  const mainMenuStyle = useMemo(() => {
    return {
      alignSelf: menuItemAlignSelf,
    };
  }, [menuItemAlignSelf]);

  const menuItemStyle = useMemo(() => {
    return {
      flex: investingWithGrandviewFlex,
    };
  }, [investingWithGrandviewFlex]);

  const investingWithGrandviewStyle = useMemo(() => {
    return {
      alignSelf: menuItemAlignSelf1,
      lineHeight: menuItemLineHeight,
    };
  }, [menuItemAlignSelf1, menuItemLineHeight]);

  const LanguageToggle = () => (
    <div className="flex items-center space-x-1">
      <button
        onClick={() => changeLanguage("en")}
        className={`px-1 py-0.5 text-xs rounded ${i18n.language === "en" ? "bg-green-700  text-white" : "bg-light-grey  text-black"}`}
      >
        ENG
      </button>
      <button
        onClick={() => changeLanguage("zh")}
        className={`px-1 py-0.5 text-xs rounded ${i18n.language === "zh" ? "bg-green-700 text-white" : "bg-gray-300 text-black"}`}
      >
        中文
      </button>
    </div>
  );

  return (
    <header className="bg-light-grey fixed top-0 left-0 self-stretch px-5 sm:px-10 lg:px-15 z-30 flex flex-row items-center justify-between py-15 sm:pt-6 lg:py-[10px] box-border min-h-[100px] sm:min-h-[150px] lg:min-h-[199px] w-full gap-[8px] 
      text-sm 
      mq1024:text-base
      mq1350:text-[14px]
      mq1500:text-[16px]
      mq1650:text-[18px]
      xl:text-[20px]
      text-left text-ocean-blue font-aeroport"
    >
      <Link to="/">
        <img
          className="h-auto w-[180px] xs:w-[200px] sm:w-[250px] md:w-[280px] lg:w-[300px] relative object-contain pl-4 sm:pl-0 xm:pl-0 lg:pl-0"
          loading="lazy"
          alt="Grandview Capital logo"
          src="/logo.png"
        />
      </Link>

      <div className="flex items-center gap-4 lg:hidden">
        <div className="flex flex-col items-end">
          <div className="mb-2">
            <LanguageToggle />
          </div>
          <div className="flex items-center gap-4">
            <Link
              to="https://grandviewcapital-admin.firebaseapp.com/"
              className="text-teal-green text-[10px] lg:text-xl transition-colors duration-200 ease-in-out no-underline font-bold"
            >
              {t("CLIENTLOGIN")}
            </Link>
            <button
              onClick={toggleMenu}
              className="relative bg-light-grey p-2 rounded"
            >
              {menuOpen ? (
                <RxCross2 size={30} color="black" />
              ) : (
                <MdOutlineMenu size={30} color="black" />
              )}
            </button>
          </div>
        </div>
      </div>

      <div
        className={`lg:flex ${menuOpen ? "block" : "hidden"} lg:flex`}
        style={mainNavStyle}
      >
        <div
          className="lg:flex lg:flex-row lg:items-center lg:justify-end lg:gap-[30px] text-xl text-black1"
          style={mainMenuStyle}
        >
          {/* Mobile sidebar */}
          {menuOpen && (
            <div className="fixed inset-0 bg-light-grey z-40 flex flex-col p-4">
              <div className="flex justify-between items-center w-full mb-4">
                <button
                  onClick={toggleMenu}
                  className="bg-light-grey p-2 rounded"
                >
                  <RxCross2 size={30} color="black" />
                </button>
              </div>
              <div className="flex flex-col items-center gap-4 w-full">
                <FlyoutLink
                  href="#"
                  className="hover:underline relative text-xl"
                  FlyoutContent={InvestingWithGrandviewContent}
                >
                  {t("INVESTINGWITHGRANDVIEW")}
                </FlyoutLink>
                <Link
                  to="#"
                  className="text-teal-green text-xl transition-colors duration-200 ease-in-out no-underline"
                >
                  {t("INVESTMENTOPTIONS")}
                </Link>
                <FlyoutLink
                  href="#"
                  className="hover:underline text-xl text-red-500"
                  FlyoutContent={AboutContent}
                >
                  {t("ABOUT")}
                </FlyoutLink>
                <Link
                  to="https://forms.gle/YgNmwb274nN3yRZRA"
                  className="text-teal-green text-xl transition-colors duration-200 ease-in-out no-underline"
                >
                  {t("CONTACT")}
                </Link>
                <Link
                  to="https://grandviewcapital-admin.firebaseapp.com/"
                  className="text-teal-green text-xl transition-colors duration-200 ease-in-out no-underline"
                >
                  {t("CLIENTLOGIN")}
                </Link>
                <Link to="/contact" className="no-underline">
                  <button className="button cursor-pointer border-none p-5 bg-teal-green hover:bg-mint-green rounded-31xl overflow-hidden flex flex-col items-center justify-center whitespace-nowrap">
                    <b className="relative text-xl inline-block font-abeezee text-mint-green">
                      {t("MEETWITHUS")}
                    </b>
                  </button>
                </Link>
              </div>
            </div>
          )}

          {/* Desktop navigation */}
          {/* Desktop navigation */}
      <div className="hidden lg:flex lg:flex-row lg:items-center lg:justify-end 
        mq1024:gap-[15px]
        mq1350:gap-[20px]
        mq1500:gap-[25px]
        mq1650:gap-[30px]
        text-black1"
      >
           {/* Investing With Grandview */}
        <div className="flex-1 flex flex-col items-center justify-center 
          mq1024:py-3 
          mq1350:py-4 
          mq1500:py-4
          py-5 px-2"
        >
          <div className="leading-normal whitespace-nowrap">
            <FlyoutLink
              href="#"
              className="hover:underline relative whitespace-nowrap"
              FlyoutContent={InvestingWithGrandviewContent}
            >
              {t("INVESTINGWITHGRANDVIEW")}
            </FlyoutLink>
          </div>
        </div>
             {/* Investment Options */}
        <div className="flex flex-col items-center justify-center 
          mq1024:py-3 
          mq1350:py-4 
          mq1500:py-4
          py-5 px-2"
        >
          <div className="relative inline-block 
            mq1024:min-w-[60px]
            mq1350:min-w-[65px]
            mq1500:min-w-[70px]
            min-w-[76px]"
          >
            <Flyout>
              <Link
                to="#"
                className="text-teal-green text-center transition-colors duration-200 ease-in-out no-underline whitespace-nowrap"
              >
                {t("INVESTMENTOPTIONS")}
              </Link>
            </Flyout>
          </div>
        </div>

            {/* About */}
        <div className="flex flex-col items-center justify-center 
          mq1024:py-3 
          mq1350:py-4 
          mq1500:py-4
          py-5 px-[9px]"
        >
          <div className="relative inline-block min-w-[58px]">
            <FlyoutLink
              href="#"
              className="hover:underline text-red-500 whitespace-nowrap"
              FlyoutContent={AboutContent}
            >
              {t("ABOUT")}
            </FlyoutLink>
          </div>
        </div>
             {/* Contact */}
        <div className="flex flex-col items-center justify-center 
          mq1024:py-3 
          mq1350:py-4 
          mq1500:py-4
          py-5 px-2"
        >
          <div className="relative inline-block min-w-[76px]">
            <Flyout>
              <Link
                to="https://forms.gle/YgNmwb274nN3yRZRA"
                className="text-teal-green text-center transition-colors duration-200 ease-in-out no-underline whitespace-nowrap"
              >
                {t("CONTACT")}
              </Link>
            </Flyout>
          </div>
        </div>

             {/* Client Login */}
        <div className="flex flex-col items-center justify-center 
          mq1024:py-3 
          mq1350:py-4 
          mq1500:py-4
          py-5 px-2"
        >
          <div className="relative inline-block min-w-[76px]">
            <Flyout>
              <Link
                to="https://grandviewcapital-admin.firebaseapp.com/"
                className="text-teal-green text-center transition-colors duration-200 ease-in-out no-underline whitespace-nowrap"
              >
                {t("CLIENTLOGIN")}
              </Link>
            </Flyout>
          </div>
        </div>

            {/* Meet With Us 按钮 */}
        <div className="relative">
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-4">
            <LanguageToggle />
          </div>
          <Link to="/contact" className="no-underline">
            <button className="button cursor-pointer border-none 
              mq1024:p-3 
              mq1350:p-4 
              mq1500:p-4
              p-5 
              bg-teal-green hover:bg-mint-green rounded-31xl overflow-hidden flex items-center justify-center"
            >
              <b className="relative 
                mq1024:text-sm
                mq1350:text-base
                mq1500:text-lg
                text-xl 
                inline-block font-abeezee text-mint-green whitespace-nowrap"
              >
                {t("MEETWITHUS")}
              </b>
            </button>
          </Link>
        </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;