import React, { useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const AccordionItem = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="lg:block"> 
      <div className="lg:hidden border-b border-teal-green/10">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full flex items-center justify-between py-2 bg-light-grey focus:outline-none"
        >
          <span className="leading-[34px] text-[12px] sm:text-[12px] font-bold">
            {title}
          </span>
          {isOpen ? (
            <MdKeyboardArrowUp size={24} className="text-teal-green" />
          ) : (
            <MdKeyboardArrowDown size={24} className="text-teal-green" />
          )}
        </button>

        <div
          className={`overflow-hidden transition-all duration-300 ease-in-out bg-light-grey ${
            isOpen ? 'max-h-[500px] pb-1' : 'max-h-0'
          }`}
        >
          {children}
        </div>
      </div>

      <div className="hidden lg:block">
        <b className="leading-[34px] text-[20px] lg:leading-[30px] block mb-1">
          {title}
        </b>
        {children}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="w-full overflow-hidden bg-light-grey text-teal-green font-abeezee">
      <div className="container mx-auto px-6 py-6">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-1 lg:gap-1">
          {/* Logo Column */}
          <div>
            <img
              className="h-auto w-[180px] xs:w-[200px] sm:w-[250px] md:w-[280px] lg:w-[300px] relative object-contain mb-6"
              loading="lazy"
              alt="Grandview Capital logo"
              src="/logo.png"
            />
            <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px]">
              <p className="m-0">admin@grandviewcapital.co</p>
              <p className="m-0">(672)-727-7776</p>
            </div>
          </div>

          {/* Middle columns with reduced spacing */}
          <div className="lg:px-4">
            <AccordionItem title="Investing">
              <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] font-open-sans space-y-1">
                <p className="m-0">Investment Philosophy</p>
                <p className="m-0">Why EMD?</p>
                <p className="m-0">Investment Options</p>
              </div>
            </AccordionItem>
          </div>

          <div className="lg:px-4">
            <AccordionItem title="About">
              <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] font-open-sans space-y-1">
                <p className="m-0">About Us</p>
                <p className="m-0">Our Advisory Team</p>
                <p className="m-0">Accreditations</p>
                <p className="m-0">Contact Us</p>
              </div>
            </AccordionItem>
          </div>

          <div className="lg:px-4">
            <AccordionItem title="Legal">
              <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px] font-open-sans space-y-1">
                <p className="m-0">EMD Regulations</p>
                <p className="m-0">Legal Disclosures</p>
                <p className="m-0">Terms of Use</p>
                <p className="m-0">Privacy Policy</p>
              </div>
            </AccordionItem>
          </div>
        </div>

        {/* Address Section */}
        <div className="mt-2 pt-8 border-t border-teal-green/20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {/* Vancouver Office */}
            <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px]">
              <p className="font-bold m-0">Vancouver Office:</p>
              <p className="m-0">1205-6388 No.3 Rd,</p>
              <p className="m-0">Richmond BC V6Y 0L4</p>
            </div>
            {/* Toronto Office */}
            <div className="leading-[34px] text-[12px] sm:text-[12px] lg:text-[20px] lg:leading-[30px]">
              <p className="font-bold m-0">Toronto Office:</p>
              <p className="m-0">302-1835 Young St,</p>
              <p className="m-0">Toronto ON M4S 1X8</p>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 text-center text-[12px] sm:text-[12px] lg:text-[16px]">
          <p className="m-0">© {new Date().getFullYear()} Grandview Capital. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;