import React, { useRef, startTransition } from "react";
import emailjs from "@emailjs/browser";
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    startTransition(() => {
      emailjs
        .sendForm(
          "service_4xsr8ab",
          "template_l587s4w",
          form.current,
          "pxWnSVAgU5GBd3LHn"
        )
        .then(
          () => {
            console.log("SUCCESS!");
            console.log("Email sent successfully");
            // Optionally, add logic here to clear the form or provide user feedback
          },
          (error) => {
            console.log("FAILED...", error.text);
            // Optionally, provide error feedback to the user
          }
        );
    });
  };

  return (
    <div className="relative bg-light-grey min-h-screen flex flex-col pt-0 px-4 sm:px-6 lg:px-8 xl:px-12 pb-[75.2px]">
      <main className="flex flex-1 items-center justify-center w-full py-12 lg:py-20">
        <div className="w-full max-w-7xl flex flex-col md:flex-row shadow-2xl">
          {/* Left side: Text and contact information */}
          <div className="md:w-1/2 bg-[#003233] text-white p-8 sm:p-12 lg:p-16 xl:p-24 flex flex-col justify-between">
            <div>
              <h2 className="text-4xl sm:text-5xl lg:text-[40px] xl:text-[50px] font-bold mb-4">Have a question?</h2>
              <h2 className="text-4xl sm:text-5xl lg:text-6xl xl:text-[30px] font-bold mb-8">We're here to help.</h2>
              <p className="text-xl sm:text-2xl lg:text-3xl mb-12">
                Provide a few details in the contact form and the appropriate team member will respond shortly.
              </p>
            </div>
            <div className="space-y-6">
              <div className="flex items-center space-x-4">
                <FaMapMarkerAlt className="text-3xl" />
                <span className="text-xl">1205-6388 No.3 Rd, Richmond, BC V6Y 0L4</span>
              </div>
              <div className="flex items-center space-x-4">
                <FaPhone className="text-3xl" />
                <span className="text-xl">(672)-727-7776</span>
              </div>
              <div className="flex items-center space-x-4">
                <FaEnvelope className="text-3xl" />
                <span className="text-xl">admin@grandviewcapital.co</span>
              </div>
            </div>
          </div>

          {/* Right side: Contact form */}
          <div className="md:w-1/2 bg-white p-8 sm:p-12 lg:p-16 xl:p-24">
            <h3 className="text-3xl sm:text-4xl lg:text-[25px] font-bold mb-12">Send Us A Message</h3>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="flex flex-col gap-y-10"
            >
              <div>
                <input
                  type="text"
                  name="user_name"
                  placeholder="First and last name *"
                  className="w-full p-4 text-xl border-b border-gray-300 focus:outline-none focus:border-[#003233]"
                  required
                />
              </div>

              <div>
                <input
                  type="email"
                  name="user_email"
                  placeholder="Email address *"
                  className="w-full p-4 text-xl border-b border-gray-300 focus:outline-none focus:border-[#003233]"
                  required
                />
              </div>

              <div>
                <textarea
                  name="message"
                  placeholder="Message *"
                  className="w-full p-4 text-xl border-b border-gray-300 focus:outline-none focus:border-[#003233] h-48"
                  required
                ></textarea>
              </div>

              <div className="flex items-center">
                <button
                  type="submit"
                  className="bg-[#003233] text-white py-4 px-8 text-xl rounded hover:bg-[#004545] transition duration-300"
                >
                  Send Message
                </button>
                <div className="ml-4 text-[#003233]">
                  {/* You can add an icon here if needed */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Contact;